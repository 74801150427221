<template>
    <div v-if="!loading">
        <div class="form-profileg row">
            <div class="col-md-12">
                <p class="form-profile__subtitle">
                    <span class="fas fa-user"></span>
                    Profile
                </p>
            </div>
            <div v-if="!isHidden('first_name')" class="col-md-6 mb-4">
                <ui-input
                    id="name"
                    name="name"
                    label="Profile name"
                    type="text"
                    v-model:value="formData.name"
                    :field="v$.formData.name"
                    :disabled="isDisabled('name')"
                />
            </div>
            <div v-if="!isHidden('role')" class="col-md-6 mb-4">
                <ui-select
                    id="role"
                    name="role"
                    class="test-class w-100"
                    label="Role"
                    option-label="key"
                    option-value="value"
                    v-model:value="formData.role"
                    :options="roles"
                    :disabled="isDisabled('role')"
                />
            </div>
        </div>
        <div class="col-md-12 mb-4">
            <div>
                <h6 class="admin-scopes__subtitle">Scopes</h6>
                <p class="text-muted">Select the scopes related to this profile.</p>
            </div>
            <scopes-list @selectedScopes="handleScopes" :preSelectedScopes="selected_scopes" :disabled="disabled"/>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import UiInput from '@/components/ui/Input.vue';
import UiSelect from '@/components/ui/Select.vue';
import ScopesList from '@/components/admins/scopes/ScopesList.vue';

import profile_from_mixin from '@/mixins/profile-form.mixin';

export default {
    name: 'FormAdmin',
    components: { UiInput, UiSelect, ScopesList},
    mixins: [profile_from_mixin],
    props: {
        defaultData: {
            type: Object,
            default: () => {},
            required: false,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            roles: [
                {
                    key: 'Superadmin',
                    value: 'superadmin',
                },
                {
                    key: 'Admin',
                    value: 'admin',
                },
                {
                    key: 'User',
                    value: 'user',
                },
            ],
            selected_scopes: [],
            loading: false,
            disabled: false,
        };
    },
    mounted() {
        this.loadDefaulData();
    },
    methods: {
        isDisabled(inputName) {
            return this.disabledInputs.includes(inputName);
        },
        isHidden(inputName) {
            return this.hiddenInputs.includes(inputName);
        },
        loadDefaulData() {
            if (!this.defaultData) return;
            for(const inputName in this.formData) {
                if (this.defaultData[inputName]) {
                    this.formData[inputName] = this.defaultData[inputName];
                }
            }
        },
        handleScopes(scopes) {
            this.formData.scopes = scopes;
        },
    },
}
</script>

<style lang="scss">
.form-profile{
    &__subtitle {
        color: $ecart-secondary-500;
        margin-bottom: 16px;
    }

    &__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }

    &__column {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 100%;
        > div {
            flex-basis: calc(33.33% - 10px);
            margin-bottom: 10px;
        }
    }

    &__title {
        color: $ecart-primary-400;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 1rem;
    }
}
</style>

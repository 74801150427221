import { required } from '@vuelidate/validators'

export default {
    props: {
        disabledInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
        hiddenInputs: {
            type: Array,
            default: () => [],
            required: false,
        },
    },

    data() {
        let formData = {
            name: '',
            role: '',
            scopes: [],
        };

        formData = Object.keys(formData)
            .filter(key => !this.hiddenInputs.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: formData[key] }), {});

        return { formData };
    },

    validations () {
        let validations = {
            name: { required, $autoDirty: false },
            role: { required, $autoDirty: false },
            scopes: { required,  $autoDirty: false },
        }

        validations = Object.keys(validations)
            .filter(key => !this.hiddenInputs.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: validations[key] }), {});

        return { formData: validations };
    },

    methods: {
        async validate() {
           return await this.v$.$validate();
        },
        reset() {
            for (const key in this.formData) {
                this.formData[key] = key === 'active' ? false : '';
            }
            this.v$.formData.$reset();
        },
    },
};
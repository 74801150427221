<template>
    <div class="edit-profile">
        <b-button class="mb-3" variant="light" @click="$router.back()">
            <i class="fas fa-chevron-left"></i> Back
        </b-button>
        <div>
            <ui-card title="Edit Profile">
                <div v-if="loading" class="py-5 text-center">
                    <custom-spinner class="spinner-border-md" />
                </div>
                <form-profile v-show="!loading" ref="formProfile" />
                <div v-show="!loading" class="text-end">
                    <ui-button :loading="updating" variant="primary" @click="update">
                        Update
                    </ui-button>
                </div>
            </ui-card>
        </div>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';
import UiCard from '@/components/ui/Card.vue';
import FormProfile from '@/components/profiles/FormProfile.vue';
import CustomSpinner from '@/components/ui/CustomSpinner.vue';

export default {
    components: { UiButton, UiCard, FormProfile, CustomSpinner },
    data() {
        return {
            loading: false,
            updating: false,
        }
    },
    async mounted() {
        await this.getProfile();
    },
    methods: {
        async getProfile() {
            try {
                this.loading = true;

                const { data } = await this.$axios.get(`/_/profiles/${this.$route.query.id}`);
                const formProfile = this.$refs['formProfile'];

                formProfile.formData = {
                    name: data.name,
                    role: data.role,
                    scopes: data.scopes,                    
                };

                formProfile.selected_scopes = data.scopes;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async update() {
            try {
                this.updating = true;
                const formProfile = this.$refs['formProfile'];
                formProfile.v$.$validate();
                if (formProfile.v$.$error) {
                    this.$toast.error('Please fill in the required fields');
                    return;
                }
                const formData = formProfile.formData;
                const data = {
                    name: formData.name,
                    role: formData.role,
                    scopes: formData.scopes,
                };
                await this.$axios.put(`/_/profiles/update/${this.$route.query.id}`, data);
                this.$toast.success('Profile updated successfully');
                this.$router.push({ name: 'profiles' });
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.updating = false;
            }
        },
    }
}
</script>

<style lang="scss">
.edit-profile {
    &__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }

    &__column {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 100%;
        > div {
            flex-basis: calc(33.33% - 10px);
            margin-bottom: 10px;
        }
    }

    &__title {
        color: $ecart-primary-400;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 1rem;
    }
}
</style>